@import "../../color";
@import "../../variables";
@import "../../themes";

.btn {
  /*define button default*/
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  transition: all ease-in-out 0.31s;
  cursor: pointer;
  //background-color: transparent;
  border-radius: 0;
  border: none;
  font-size: 1.125rem;
  box-shadow: -6px 0 6px rgba(255, 255, 255, .75);
  text-shadow: none;
  font-family: $font-family-display;
  font-weight: $font-weight-light;
  /*define button size: small(sm) - normal(sd) - Large(sl) - larger(sxl)*/

  &-sm {
    padding: 0.25rem 0.75rem;
    font-size: $font-size-small;
    &-x{
      padding: 0.25rem 0.75rem;
      font-size: $font-size-x-small;
    }
    &-xx{
      padding: 0.125rem 0.5rem;
      font-size: $font-size-xx-small;
    }
  }
  &-sd {
    padding: 0.375rem 1.125rem;
    font-size: $font-size-base;
  }
  &-sl {
    padding: 0.5rem 1.325rem;
    font-size: $font-size-large;

    &-x{
      padding: 0.625rem 1.5rem;
      font-size: $font-size-x-large;
    }
    &-xx{
      padding: 0.75rem 1.625rem;
      font-size: $font-size-xx-large;
    }
    &-xxx{
      padding: 0.875rem 1.75rem;
      font-size: $font-size-xxx-large;
    }
    &-xxxx{
      padding: 1rem 1.875rem;
      font-size: $font-size-xxxx-large;
    }
  }


  &.eclip {
    border-radius: 1.125rem;
    &.vertical {
      padding: 0.5rem 0.875rem;
      font-size: 1.325rem;
      width: 2.25rem;
      height: 4rem;
      margin-left: -1.125rem;
      align-items: center;
      display: flex;
      box-shadow: 1px 1px 3px rgba(0,0,0,0.35);
      background-color: rgba(255,255,255,0.35);
    }
    &.horizontal {
      padding: 0.25rem 1.5rem;
      border-radius: 1.25rem;
    }
  }

  //define button style
  &-bg {
    //background default
    background-color: $gray300Color;
    color: $gray900Color;

    //transparent
    &-transparent {
      background-color: transparent;
      color: $gray900Color;
      &-35{
        background-color: rgba(255,255,255,0.35);
      }
    }

    //background for theme
    &-ft {

    }
    &-orange {
      background-color: $orangeA700Color;
      color: $orange100Color;
      &:hover,
      &:focus {
        color: $whiteColor;
        background-color: $orange900Color;
      }
    }
  }
  //button outline with border
  &-outline {

  }

  &.shadow-none {

  }

  //position
  &.top {
    top: $position-distance-0;
  }
  &.left {
    left: $position-distance-0;
  }
  /*Define button color*/
  @include themify($themes) {
    &.eclip {
      border: themed('border');
      &:hover,
      &:focus{
        border: themed('borderLight');
        background-color: themed('bgColor');
        color: themed('whiteColor');
      }
    }
    &-bg {
      &-ft {
        background-color: themed('bgColor') !important;
        color: themed('whiteColor') !important;
        &:hover {
          background-color: themed('darkenColor') !important;
        }
      }
      //transparent
      &-transparent {
        &-35{
          &:hover,
          &:focus{
            background-color: themed('bgColor');
            color: themed('whiteColor');
          }
        }
      }

    }
  }
}