.modal-customer-register {
  max-width: 100%;
  padding: 1.5rem;
  .modal-header {
    display: block;
    padding: 0;
    border-width: 0px;
  }
  .modal-content {
    padding: 0;
    border: 4px solid white;
    margin: auto;
    background: rgba(0, 0, 0, 0.75);
    text-align: center;
    color: white;
    border-radius: 0;
  }
  .btn-customer-register {
    background: red;
    color: white;
    padding-left: 40px;
    padding-right: 40px;
  }
  .close, .close:focus, .close:active {
    position: absolute;
    top: -1.875rem;
    right: -2.125rem;
    border: 2px solid white;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    font-size: 18px;
    color: white;
    padding: 0;
    margin: 0;
  }

  // Extra small devices (portrait phones, less than 576px)
  // No media query for `xs` since this is the default in Bootstrap

  // Small devices (landscape phones, 576px and up)
  @media (min-width: 576px) {  }

  // Medium devices (tablets, 768px and up)
  @media (min-width: 768px) {
    max-width: 25rem;
    padding: 1.5rem 2.5rem;
  }

  // Large devices (desktops, 992px and up)
  @media (min-width: 992px) {  }

  // Extra large devices (large desktops, 1200px and up)
  @media (min-width: 1200px) {

  }
}