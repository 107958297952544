//import reset base style
@import "base/index";

//import layout for this site
@import "layout/index";

//import plugin
@import "plugin/index";

//import plugin
@import "page/index";

//import all component
@import "component/index";