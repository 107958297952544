$version: '1.0.0';
$author: 'Leo Dao';
$githubLink:'https://github.com/jackiedao';


@import "color";

//Definition variables
//shadow size
$shadowLength: 0 !default;
$shadowLength16: 16 !default;

//define font family
//Content
$font-family-text: 'Open Sans', sans-serif;
//title
$font-family-display: 'Encode Sans', sans-serif;
//Font specific
$font-family-specific: 'Dancing Script', cursive;

//define font size
$font-size-base: 1rem !default;
$font-size-small: ($font-size-base * 0.875) !default;
$font-size-x-small: ($font-size-base * 0.75) !default;
$font-size-xx-small: ($font-size-base * 0.625) !default;
$font-size-xxx-small: ($font-size-base * 0.5) !default;
$font-size-large: ($font-size-base * 1.5) !default;
$font-size-x-large: ($font-size-base * 2) !default;
$font-size-xx-large: ($font-size-base * 3) !default;
$font-size-xxx-large: ($font-size-base * 4) !default;
$font-size-xxxx-large: ($font-size-base * 6) !default;


//define font weight
$font-weight-light: 300 !default;
$font-weight-regular: 400 !default;
$font-weight-bold: 600 !default;
$font-weight-bolder: 800 !default;

//define position
$position-distance-base: 1rem !default;
$position-distance-0: ($position-distance-base * 0) !default;
$position-distance-1: ($position-distance-base * 0.75) !default;
$position-distance-2: ($position-distance-base * 1.5) !default;
$position-distance-3: ($position-distance-base * 3) !default;
$position-distance-4: ($position-distance-base * 4) !default;
$position-distance-5: ($position-distance-base * 6) !default;

//define z-index
$zindex-dropdown:          1000 !default;
$zindex-sticky:            1020 !default;
$zindex-fixed:             1030 !default;
$zindex-modal-backdrop:    1040 !default;
$zindex-modal:             1050 !default;
$zindex-popover:           1060 !default;
$zindex-tooltip:           1070 !default;

//define radius 0-100
$radius-base: 1rem !default;
$radius-0: ($radius-base * 0) !default;
$radius-1: ($radius-base * 0.25) !default;
$radius-2: ($radius-base * 0.5) !default;
$radius-3: ($radius-base * 0.75) !default;
$radius-4: ($radius-base * 1.5) !default;
$radius-5: ($radius-base * 2) !default;
$radius-6: ($radius-base * 3) !default;
$radius-7: ($radius-base * 4) !default;
$radius-8: ($radius-base * 6) !default;
$radius-full: 100% !default;