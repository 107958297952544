@import "../../variables";
@import "../../themes";

.section {
  position: relative;
  &-home {
    //overflow: hidden;
    //padding: 0;
    //padding-top: 3rem;
  }
  &-banner {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    position: relative;
  }
  &-mission {
    min-height: 80vh;
  }
  &-store {
    background-attachment: fixed;
    background-size: cover;
    padding-top: 4rem;
    height: 100vh;
    .title {
      font-size: $font-size-large;
    }
    &.section-home {
      &::before {
        display: none;
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        background-color: $whiteColor;
        opacity: 0.35;
        z-index: 2;
      }
    }
  }
  &-menu {
    //background-attachment: fixed;
    //background-size: cover;
    background-color: cornflowerblue;
    padding: 4rem 0;
  }
  &-news {
    height: initial;
    padding: 3.625rem 1.5rem;
  }

  // Extra small devices (portrait phones, less than 576px)
  // No media query for `xs` since this is the default in Bootstrap

  // Small devices (landscape phones, 576px and up)
  @media (min-width: 576px) {
  }

  // Medium devices (tablets, 768px and up)
  @media (min-width: 768px) {
    &-home {
      //height: auto;
      //min-height: initial;
      overflow: hidden;
      padding: 0;
    }
    &-menu {
      padding: 4rem 0;
      //background-color: chocolate;
    }
    &-news {
      height: auto;
      //padding: 4rem 0;
      min-height: auto;
    }
    &-store {
      padding-top: 0;
      &.section-home {
        &::before {
          width: 50%;
          display: table;
        }
      }
    }
    &-banner {
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
      position: relative;
    }
  }

  // Large devices (desktops, 992px and up)
  @media (min-width: 992px) {
    &-store {
      .title {
        font-size: $font-size-x-large;
      }
    }
  }

  // Extra large devices (large desktops, 1200px and up)
  @media (min-width: 1200px) {
    height: 100vh;
    &-news {
      height: auto;
    }
  }

  // Extra large devices (large desktops, 1200px and up)
  @media (min-width: 1280px) {
    height: 100vh;
    &-banner {
      height: auto;
    }
    &-news {
      height: auto;
    }
  }

  // Extra large devices (large desktops, 1200px and up)
  @media (min-width: 1440px) {
    height: 100vh;
    &-news {
      height: auto;
    }
  }

  //set color for theme
  @include themify($themes) {

    &-store {
      .title {
        color: themed('defaultColor');
      }
    }
    &-menu{
      //background-color: themed('bgColor');
    }
  }
}