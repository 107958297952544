@import "color.scss";

.blog-detail {
  margin: 10px 30px 0 30px;
  padding: 30px 65px;
  color: white;
  .back-drop {
    //background: $main-green;
    opacity: 0.8;
  }
}

.blog {
  padding: 0 30px;
  img {
    width: 100%;
  }
  .back-drop {
    width: 100%;
    height: 100px;
    //background: $main-green;
    bottom: 0;
    opacity: 0.6;
  }
  .title-box {
    height: 100px;
    bottom: 0;
    border: 3px solid #1b550d;
    width: 100%;
    padding: 10px;
    color: white;
  }
}

.related-news {
  ul {
    list-style-type: none;
    padding: 0;
    li {
      padding: 10px 0 10px 10px;
    }
  }
  a {
    color: white;
  }
}

.card-news-item {
  background: transparent;
  border: none;
  .card-title {
    color: white;
  }
}

.box-news {
  img {
    height: 181px;
  }
  .title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}