@import "../../themes";

.side-menu {
  width: 16rem;
  left: -16rem;
  .nav {
    &-item {

    }
    &-link {
      transition: all ease-in-out 0.32s;
      border-bottom: 1px solid transparent;
    }
  }
  &-open {
    & + .btn-toggle {
      transition: all ease-in-out 0.32s;
    }
  }

  // Extra small devices (portrait phones, less than 576px)
  // No media query for `xs` since this is the default in Bootstrap

  // Small devices (landscape phones, 576px and up)
  @media (min-width: 576px) {

  }

  // Medium devices (tablets, 768px and up)
  @media (min-width: 768px) {

  }

  // Large devices (desktops, 992px and up)
  @media (min-width: 992px) {

  }

  // Extra large devices (large desktops, 1200px and up)
  @media (min-width: 1200px) {

  }
  @media (min-width: 1280px) {

  }
  @media (min-width: 1440px) {

  }

  //set color style for themed

  @include themify($themes) {
    background-color: themed('bgColor');
    .nav {
      &-item {

      }
      &-link {
        border-bottom-color: themed('borderColorLight');
        color: themed('lightenColor');
        &:hover,
        &.active {
          background-color: themed('bgColorDark');
          color: themed('whiteColor');
        }
      }
    }
    &-open {
      & + .btn-toggle {
        background-color: themed('bgColor');
        color: themed('whiteColor');
      }
    }
  }
}