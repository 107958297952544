@import "../../color";

.bg-solid {
  background: $blueGray500Color;
}

.bg-solid-light {
  background: $blueGray400Color;
}

.color-white {
  color: white;
}

.intro {
  img {
    max-width: 100%;
    height: auto;
  }
}