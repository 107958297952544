@import "../../variables";

.hotline {
  &-top {
    margin-left: 3.5rem;
    display: none;
  }

  &-ob {
    font-size: $font-size-large;
    padding: 0.5rem 0.75rem;
    font-weight: $font-weight-regular;
    //text-shadow: 1px 1px 3px rgba(255,255,255,0.35);
  }
  // Extra small devices (portrait phones, less than 576px)
  // No media query for `xs` since this is the default in Bootstrap

  // Small devices (landscape phones, 576px and up)
  @media (min-width: 576px) {

  }

  // Medium devices (tablets, 768px and up)
  @media (min-width: 768px) {
    &-top {
      display: initial;
    }
  }

  // Large devices (desktops, 992px and up)
  @media (min-width: 992px) {

  }

  // Extra large devices (large desktops, 1200px and up)
  @media (min-width: 1200px) {

  }
  @media (min-width: 1280px) {

  }
  @media (min-width: 1440px) {

  }
}