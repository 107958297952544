@import "../../mixins";
@import "../../color";
@import "../../variables";

.position {
  &-absolute {
    &.top {
      top: 0 !important;
    }
    &.right {
      right: 0 !important;
    }
    &.bottom {
      bottom: 0 !important;
      &-1 {
        bottom: 1rem;
      }&-2 {
        bottom: 2rem;
      }&-3 {
        bottom: 3rem;
      }
    }
    &.left {
      left: 0 !important;
    }
    &.middle {
      top: 50% !important;
    }
    &.center {
      left: 50% !important;
    }
  }
  &-fixed {
    position: fixed;
  }
}

//define color
.color {
  color: $gray900Color;

  &-red {
    color: $redA700Color;
  }
}

//z-index
.zindex {
  z-index: 1;
  &-1 {
    z-index: 9;
  }
  &-2 {
    z-index: 99;
  }
  &-3 {
    z-index: 999;
  }
  &-4 {
    z-index: 9999;
  }
}

//width
.width {
  &-1, &-2, &-3, &-4, &-5, &-6, &-7, &-8, &-9, &-10 {
    width: 0;
    overflow: hidden;
  }

  //width: 10%
  &-1 {
    width: 10%
  }
  //width: 20%
  &-2 {
    width: 20%
  }
  //width: 30%
  &-3 {
    width: 30%
  }
  //width: 40%
  &-4 {
    width: 40%
  }
  //width: 50%
  &-5 {
    width: 50%
  }
  //width: 60%
  &-6 {
    width: 60%
  }
  //width: 70%
  &-7 {
    width: 70%
  }
  //width: 80%
  &-8 {
    width: 80%
  }
  //width: 90%
  &-9 {
    width: 90%
  }
  //width: 100%
  &-10 {
    width: 100%
  }
}

//background base
.bg {
  &-transparent {
    background-color: transparent;
  }
  &-orange {
    background-color: $orangeA700Color;
  }
}

.display {
  &-6 {
    font-size: 6rem;
    font-weight: $font-weight-regular;
  }
}

.line {
  &-vertical {
    padding: 0 1.75rem;
    position: relative;
    &::before,
    &::after {
      display: table;
      width: 1.54rem;
      height: 0.125rem;
      background-color: white;
      position: absolute;
      content: '';
      top: 50%;
      margin-top: -1px;
    }
    &::before {
      left: 0;
    }
    &::after {
      right: 0;
    }
  }
}

//
.blur5 {
  filter: blur(30px);
  -webkit-filter: blur(30px);
  -moz-filter: blur(30px);
  -o-filter: blur(30px);
  -ms-filter: blur(30px);
  filter: url(#wherearemyglasses);
}

//radius
.circle {
  border-radius: $radius-full;
}

.z-index-0 {
  z-index: 0;
}

.z-index-1 {
  z-index: 1;
}

.z-index-2 {
  z-index: 2;
}

.input-error, .required {
  color: red;
}

// Extra small devices (portrait phones, less than 576px)
// No media query for `xs` since this is the default in Bootstrap
@media (min-width: 280px) {
  .position-xs {
    &-absolute {
      position: absolute;
    }
    &-fixed {
      position: fixed;
    }
  }
  .top-xs {
    top: 0 !important;
    &-2 {
      top: 2rem
    }
    &-3 {
      top: 3rem
    }
    &-4 {
      top: 4rem
    }
    &-5 {
      top: 5rem
    }
  }
  .right-xs {
    right: 0 !important;
  }
  .bottom-xs {
    bottom: 0 !important;
    &-1 {
      bottom: 1rem;
    }
  }
  .left-xs {
    left: 0 !important;
  }
  .middle-xs {
    top: 50% !important;
  }
  .center-xs {
    left: 50% !important;
  }
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
  .position-md {
    &-absolute {
      position: absolute;
    }
    &-fixed {
      position: fixed;
    }

  }
  .top-md {
    top: 0 !important;
  }
  .right-md {
    right: 0 !important;
  }
  .bottom-md {
    bottom: 0 !important;
    &-1 {
      bottom: 1rem;
    }
  }
  .left-md {
    left: 0 !important;
  }
  .middle-md {
    top: 50% !important;
  }
  .center-md {
    left: 50% !important;
  }
  .width-md {
    &-1, &-2, &-3, &-4, &-5, &-6, &-7, &-8, &-9, &-10 {
      width: 0;
      overflow: hidden;
    }

    //width: 10%
    &-1 {
      width: 10%
    }
    //width: 20%
    &-2 {
      width: 20%
    }
    //width: 30%
    &-3 {
      width: 30%
    }
    //width: 40%
    &-4 {
      width: 40%
    }
    //width: 50%
    &-5 {
      width: 50%
    }
    //width: 60%
    &-6 {
      width: 60%
    }
    //width: 70%
    &-7 {
      width: 70%
    }
    //width: 80%
    &-8 {
      width: 80%
    }
    //width: 90%
    &-9 {
      width: 90%
    }
    //width: 100%
    &-10 {
      width: 100%
    }
  }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {

}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {

}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1280px) {

}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1440px) {

}