@mixin themify($themes){
  @each $theme, $map in $themes{
    .theme-#{$theme} & {
      $theme-map: () !global;

      @each $key, $submap in $map{
        $value: map_get(map_get($themes, $theme), '#{$key}');
        $theme-map: map-merge($theme-map, ($key: $value)) !global;
      }

      @content;
      $theme-map: null !global;
    }
  }
}

@mixin not-last-child($selector){
  & #{$selector}:not(:last-child){
    @content;
  }
}