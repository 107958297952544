/*========================
    *set variable color*

========================*/

/*Default Palettes Color*/
$whiteColor: #fff;
$blackColor: #000;
$redColor: #f00;

/*Gray Palettes Color*/
$gray50Color: #fafafa;
$gray100Color: #F5F5F5;
$gray200Color: #EEEEEE;
$gray300Color: #E0E0E0;
$gray400Color: #BDBDBD;
$gray500Color: #9E9E9E;
$gray600Color: #757575;
$gray700Color: #616161;
$gray800Color: #424242;
$gray900Color: #212121;

/*Red Palettes Color*/
$red50Color: #FFEBEE;
$red100Color: #FFCDD2;
$red200Color: #EF9A9A;
$red300Color: #E57373;
$red400Color: #EF5350;
$red500Color: #F44336;
$red600Color: #E53935;
$red700Color: #D32F2F;
$red800Color: #C62828;
$red900Color: #B71C1C;
$redA100Color: #FF8A80;
$redA200Color: #FF5252;
$redA400Color: #FF1744;
$redA700Color: #D50000;

/*Pink Palettes Color*/
$pink50Color: #FCE4EC;
$pink100Color: #F8BBD0;
$pink200Color: #F48FB1;
$pink300Color: #F06292;
$pink400Color: #EC407A;
$pink500Color: #E91E63;
$pink600Color: #D81B60;
$pink700Color: #C2185B;
$pink800Color: #AD1457;
$pink900Color: #880E4F;
$pinkA100Color: #FF80AB;
$pinkA200Color: #FF4081;
$pinkA400Color: #F50057;
$pinkA700Color: #C51162;

/*Purple Palettes Color*/
$purple50Color: #F3E5F5;
$purple100Color: #E1BEE7;
$purple200Color: #CE93D8;
$purple300Color: #BA68C8;
$purple400Color: #AB47BC;
$purple500Color: #9C27B0;
$purple600Color: #8E24AA;
$purple700Color: #7B1FA2;
$purple800Color: #6A1B9A;
$purple900Color: #4A148C;
$purpleA100Color: #EA80FC;
$purpleA200Color: #E040FB;
$purpleA400Color: #D500F9;
$purpleA700Color: #AA00FF;

/*Deep purple Palettes Color*/
$deepPurple50Color: #EDE7F6;
$deepPurple100Color: #D1C4E9;
$deepPurple200Color: #B39DDB;
$deepPurple300Color: #9575CD;
$deepPurple400Color: #7E57C2;
$deepPurple500Color: #673AB7;
$deepPurple600Color: #5E35B1;
$deepPurple700Color: #512DA8;
$deepPurple800Color: #4527A0;
$deepPurple900Color: #311B92;
$deepPurpleA100Color: #B388FF;
$deepPurpleA200Color: #7C4DFF;
$deepPurpleA400Color: #651FFF;
$deepPurpleA700Color: #6200EA;

/*Indigo Palettes Color*/
$indigo50Color: #E8EAF6;
$indigo100Color: #C5CAE9;
$indigo200Color: #9FA8DA;
$indigo300Color: #7986CB;
$indigo400Color: #5C6BC0;
$indigo500Color: #3F51B5;
$indigo600Color: #3949AB;
$indigo700Color: #303F9F;
$indigo800Color: #283593;
$indigo900Color: #1A237E;
$indigoA100Color: #8C9EFF;
$indigoA200Color: #536DFE;
$indigoA400Color: #3D5AFE;
$indigoA700Color: #304FFE;

/*Blue Palettes Color*/
$blue50Color: #E3F2FD;
$blue100Color: #BBDEFB;
$blue200Color: #90CAF9;
$blue300Color: #64B5F6;
$blue400Color: #42A5F5;
$blue500Color: #2196F3;
$blue600Color: #1E88E5;
$blue700Color: #1976D2;
$blue800Color: #1565C0;
$blue900Color: #0D47A1;
$blueA100Color: #82B1FF;
$blueA200Color: #448AFF;
$blueA400Color: #2979FF;
$blueA700Color: #2962FF;

/*Light Blue Palettes Color*/
$lightBlue50Color: #E1F5FE;
$lightBlue100Color: #B3E5FC;
$lightBlue200Color: #81D4FA;
$lightBlue300Color: #4FC3F7;
$lightBlue400Color: #29B6F6;
$lightBlue500Color: #03A9F4;
$lightBlue600Color: #039BE5;
$lightBlue700Color: #0288D1;
$lightBlue800Color: #0277BD;
$lightBlue900Color: #01579B;
$lightBlueA100Color: #80D8FF;
$lightBlueA200Color: #40C4FF;
$lightBlueA400Color: #00B0FF;
$lightBlueA700Color: #0091EA;

/*Cyan Palettes Color*/
$cyan50Color: #E0F7FA;
$cyan100Color: #B2EBF2;
$cyan200Color: #80DEEA;
$cyan300Color: #4DD0E1;
$cyan400Color: #26C6DA;
$cyan500Color: #00BCD4;
$cyan600Color: #00ACC1;
$cyan700Color: #0097A7;
$cyan800Color: #00838F;
$cyan900Color: #006064;
$cyanA100Color: #84FFFF;
$cyanA200Color: #18FFFF;
$cyanA400Color: #00E5FF;
$cyanA700Color: #00B8D4;

/*Teal Palettes Color*/
$teal50Color: #E0F2F1;
$teal100Color: #B2DFDB;
$teal200Color: #80CBC4;
$teal300Color: #4DB6AC;
$teal400Color: #26A69A;
$teal500Color: #009688;
$teal600Color: #00897B;
$teal700Color: #00796B;
$teal800Color: #00695C;
$teal900Color: #004D40;
$tealA100Color: #A7FFEB;
$tealA200Color: #64FFDA;
$tealA400Color: #1DE9B6;
$tealA700Color: #00BFA5;

/*Green Palettes Color*/
$green50Color: #E8F5E9;
$green100Color: #C8E6C9;
$green200Color: #A5D6A7;
$green300Color: #81C784;
$green400Color: #66BB6A;
$green500Color: #4CAF50;
$green600Color: #43A047;
$green700Color: #388E3C;
$green800Color: #2E7D32;
$green900Color: #1B5E20;
$greenA100Color: #B9F6CA;
$greenA200Color: #69F0AE;
$greenA400Color: #00E676;
$greenA700Color: #00C853;

/*Light Green Palettes Color*/
$lightGreen50Color: #F1F8E9;
$lightGreen100Color: #DCEDC8;
$lightGreen200Color: #C5E1A5;
$lightGreen300Color: #AED581;
$lightGreen400Color: #9CCC65;
$lightGreen500Color: #8BC34A;
$lightGreen600Color: #7CB342;
$lightGreen700Color: #689F38;
$lightGreen800Color: #558B2F;
$lightGreen900Color: #33691E;
$lightGreenA100Color: #CCFF90;
$lightGreenA200Color: #B2FF59;
$lightGreenA400Color: #76FF03;
$lightGreenA700Color: #64DD17;

/*Lime Palettes Color*/
$lime50Color: #F9FBE7;
$lime100Color: #F0F4C3;
$lime200Color: #E6EE9C;
$lime300Color: #DCE775;
$lime400Color: #D4E157;
$lime500Color: #CDDC39;
$lime600Color: #C0CA33;
$lime700Color: #AFB42B;
$lime800Color: #9E9D24;
$lime900Color: #827717;
$limeA100Color: #F4FF81;
$limeA200Color: #EEFF41;
$limeA400Color: #C6FF00;
$limeA700Color: #AEEA00;

/*Yellow Palettes Color*/
$yellow50Color: #FFFDE7;
$yellow100Color: #FFF9C4;
$yellow200Color: #FFF59D;
$yellow300Color: #FFF176;
$yellow400Color: #FFEE58;
$yellow500Color: #FFEB3B;
$yellow600Color: #FDD835;
$yellow700Color: #FBC02D;
$yellow800Color: #F9A825;
$yellow900Color: #F57F17;
$yellowA100Color: #FFFF8D;
$yellowA200Color: #FFFF00;
$yellowA400Color: #FFEA00;
$yellowA700Color: #FFD600;

/*Amber Palettes Color*/
$amber50Color: #FFF8E1;
$amber100Color: #FFECB3;
$amber200Color: #FFE082;
$amber300Color: #FFD54F;
$amber400Color: #FFCA28;
$amber500Color: #FFC107;
$amber600Color: #FFB300;
$amber700Color: #FFA000;
$amber800Color: #FF8F00;
$amber900Color: #FF6F00;
$amberA100Color: #FFE57F;
$amberA200Color: #FFD740;
$amberA400Color: #FFC400;
$amberA700Color: #FFAB00;

/*Orange Palettes Color*/
$orange50Color: #FFF3E0;
$orange100Color: #FFE0B2;
$orange200Color: #FFCC80;
$orange300Color: #FFB74D;
$orange400Color: #FFA726;
$orange500Color: #FF9800;
$orange600Color: #FB8C00;
$orange700Color: #F57C00;
$orange800Color: #EF6C00;
$orange900Color: #E65100;
$orangeA100Color: #FFD180;
$orangeA200Color: #FFAB40;
$orangeA400Color: #FF9100;
$orangeA700Color: #FF6D00;

/*Deep Orange Palettes Color*/
$deepOrange50Color: #FBE9E7;
$deepOrange100Color: #FFCCBC;
$deepOrange200Color: #FFAB91;
$deepOrange300Color: #FF8A65;
$deepOrange400Color: #FF7043;
$deepOrange500Color: #FF5722;
$deepOrange600Color: #F4511E;
$deepOrange700Color: #E64A19;
$deepOrange800Color: #D84315;
$deepOrange900Color: #BF360C;
$deepOrangeA100Color: #FF9E80;
$deepOrangeA200Color: #FF6E40;
$deepOrangeA400Color: #FF3D00;
$deepOrangeA700Color: #DD2C00;

/*Brown Palettes Color*/
$brown50Color: #EFEBE9;
$brown100Color: #D7CCC8;
$brown200Color: #BCAAA4;
$brown300Color: #A1887F;
$brown400Color: #8D6E63;
$brown500Color: #795548;
$brown600Color: #6D4C41;
$brown700Color: #5D4037;
$brown800Color: #4E342E;
$brown900Color: #3E2723;

/*Blue Gray Palettes Color*/
$blueGray50Color: #ECEFF1;
$blueGray100Color: #CFD8DC;
$blueGray200Color: #B0BEC5;
$blueGray300Color: #90A4AE;
$blueGray400Color: #78909C;
$blueGray500Color: #607D8B;
$blueGray600Color: #546E7A;
$blueGray700Color: #455A64;
$blueGray800Color: #37474F;
$blueGray900Color: #263238;