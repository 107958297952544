@import "../../color";

.make-order {
  background-image: url(/images/bg-default-dark.jpg);
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  &:before {
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    content: "";
    background-color: rgba(255, 255, 255, 0.75);
  }
  .input-group {
    border: 1px solid #dddddd;
    border-radius: 6px;
    overflow: hidden;
    height: 30px;
    .input-group-addon {
      cursor: pointer;
      border: none;
      background: #eeee;
      &:hover {
        background: $yellow800Color;
        i {
          &:before {
            color: white;
          }
        }
      }
      &:first-child {
        border-right: 1px solid #ddd;
      }
      &:last-child {
        border-left: 1px solid #ddd;
      }
    }
  }
  .table-order-item {
    border: 1px solid #333333;
    font-size: 13px;
    background: white;
  }
  .form-control, .ember-power-select-trigger {
    border-radius: 0;
    padding: 2px;
  }
  .nav {
    display: block !important;
    text-align: right;
  }
  .nav-tabs {
    background-color: $blueGray500Color;
    box-shadow: 0px 0px 3px 0px;
    margin-bottom: 12px;
    .nav-item {
      display: inline-block;
    }
    .nav-link {
      color: $cyan100Color;
      border-radius: 0;
      border: none;
      &.active {
        color: $blueGray500Color;
        background-color: #fff;
        border: none;
        border-radius: 0;
        box-shadow: 0px 0px 3px 0px;
        background: $cyan100Color;
      }
    }
  }
}

.admin-make-order {
  margin-top: 30px;
  .bill,
  .menu {
    background-color: #e6e6e6;
    padding: 15px;
  }
  .bill {
    table {
      td {
        padding: 15px 5px;
      }
    }
  }
  .input-group-box {
    padding: 20px 20px 0 20px;
  }
  .input-group {
    button {
      border: 1px solid;
    }
  }
}

.menu-item {
  .col {
    &.border-left {

    }
  }
  img {
    background: white;
    width: 100%;
  }
  .menu-item-content {
    border-radius: 8px;
    border: 1px solid #adacac;
    overflow: hidden;
    box-shadow: 0px 0px 3px 0px;
  }
  vertical-align: top;
  width: 20%;
  float: left;
  //display: inline-block;
  font-size: 14px;
  padding: 0 5px;
  .menu-item-variant {
    //border: 1px solid $blueGray500Color;
    color: $blueGray500Color;
    .menu-item-name {
      padding: 3px 5px;
      background-color: $blueGray500Color;
      color: white;
    }
    .menu-item-size {
      &:hover {
        font-weight: 600;
        cursor: pointer;
      }
      &:first-child {
        border-top: none;
      }
      border-top: 1px solid $blueGray500Color;
      //padding: 7px 5px;
    }
  }
}

.menu-item-bg {
  background-color: #dddddd;
}

.menu-item-size {
  padding-top: 3px;
  padding-bottom: 3px;
  font-size: 13px;
}

.input-quantity-group {
  width: 100px;
  height: 35px;
  margin: auto;
  i {
    font-size: 16px;
  }
  .input-group-addon {
    border: 2px solid white;
    button {
      border: none;
      background-color: inherit;
      height: 100%;
      padding: 1px 10px;

      &:focus {
        border: none;
        outline: none;
      }
    }
  }
  input {
    border-color: white;
    text-align: right;
  }
}

.menu {
  height: 500px;
  overflow-y: scroll;
}

//user
.make-order {
  .bill {
    padding: 20px 35px;
    background-color: transparent !important;
    .box-order-item {
      max-height: 275px;
      overflow-y: auto;
    }
    .input-error {
      color: red;
      font-size: 13px;
    }
  }
  .menu {
    box-shadow: 0px 0px 3px 0px;
  }
  .bill,
  .menu {
    background-color: white;
    .header-toggle {
      box-shadow: 0px 0px 7px 0px;
      background: transparent;
      color: $blueGray500Color;
      padding: 7px 5px;
      margin-bottom: 12px;
    }
    .header-toggle, .header-toggle span {
      //font-size: 20px;
      padding-top: 5px;
    }
    .cp-is-open > div > span:before, .cp-is-open > div > i:before {
      content: "\f068";
    }
    .cp-Panel {
      .cp-Panel-body {
        padding: 0 2px;
      }
    }
  }
  .bill {
    table {
      td {
        padding: 15px 5px;
      }
    }
  }
}

.make-order-steps {
  color: white;
  background-color: #212121;
  padding: 20px 0px;
  text-transform: uppercase;
  ul {
    text-align: center;
    padding: 0;
    font-size: 14px;
    li {
      display: inline;
      padding: 0 30px;
    }
  }
}

.model-print {
  width: 450px;
}

.order-list {
  max-height: 400px;
  overflow-y: auto;
  overflow-x: hidden;
  margin: auto;
  padding: 15px 0;
}

.list-group {
  background: #e6e6e6;
  ul {
    //padding: 20px;
    margin: 0;
    .active {
      background: $blueGray500Color;
      color: white;
    }
    li {
      margin-bottom: 10px;
      display: inline-block;
      padding: 10px;
      border: 1px solid $blueGray500Color;
      background: white;
      cursor: pointer;
    }
  }
}

.order-shipping-info {
  background: #e6e6e6;
  color: $blueGray500Color;
  padding: 15px 15px 30px 15px;
  margin-bottom: 30px;
}

.order-done {
  img {
    width: 100%;
  }
}

.list-inline-item.border {
  margin-bottom: 10px;
  display: inline-block;
  padding: 10px;
  border: 1px solid #1b550d;
  background: #fff;
  cursor: pointer;
}

.list-inline-item.border.active {
  background: #1b550d;
  color: #fff;
}