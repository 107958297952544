@import "color";

//Definition theme

$themes: (
  purple: (
    whiteColor: $whiteColor,
    defaultColor: $purple700Color,
    lightColor: lighten($purple700Color, 35),
    lighterColor: lighten($purple700Color, 55),
    lightenColor: lighten($purple700Color, 75),
    darkColor: darken($purple700Color, 5),
    darkenColor: darken($purple700Color, 15),
    colorSpecial: $yellow800Color,
    borderColorDefault: $purple700Color,
    borderColorLight: lighten($purple700Color, 10),
    borderColorLighter: lighten($purple700Color, 25),
    borderColorLighten: lighten($purple700Color, 55),
    borderLight: 1px solid lighten($purple700Color, 55),
    border: 1px solid lighten($purple700Color, 35),
    borderDark: 2px solid darken($purple700Color, 5),
    borderDarken: 2px solid darken($purple700Color, 15),
    borderTransparent: none,
    bgColor: $purple700Color,
    bgColorDark: darken($purple700Color, 10),
    shadow: 0 0 0.25rem 0.25rem rgba(74,20,140, 0.35),
  ),
  tmc:(
    whiteColor: $whiteColor,
    defaultColor: $blueGray700Color,
    lightColor: lighten($blueGray700Color, 10),
    lighterColor: lighten($blueGray700Color, 25),
    lightenColor: lighten($blueGray700Color, 35),
    darkColor: darken($blueGray700Color, 10),
    darkerColor: darken($blueGray700Color, 25),
    darkenColor: darken($blueGray700Color, 35),
    colorSpecial: $yellow800Color,
    borderColorDefault: $blueGray700Color,
    borderColorLight: lighten($blueGray700Color, 10),
    borderColorLighter: lighten($blueGray700Color, 25),
    borderColorLighten: lighten($blueGray700Color, 55),
    borderLight: 1px solid lighten($blueGray700Color, 55),
    border: 1px solid lighten($blueGray700Color, 35),
    borderDark: 2px solid darken($blueGray700Color, 5),
    borderDarken: 2px solid darken($blueGray700Color, 15),
    borderTransparent: none,
    bgColor: $blueGray700Color,
    bgColorDark: darken($blueGray700Color, 10),
    shadow: 0 0 0.25rem 0.25rem rgba(74,20,140, 0.35)
  )
);