@import "../../color";

.menu-box {
  background-color: $blueGray500Color;
  padding: 20px 15px 40px 15px !important;
  height: 491px;
  overflow-y: scroll;
}

.table-menu {
  color: white;
  th {
    padding-bottom: 10px;
  }
  td {
    padding: 5px;
    border-bottom: 1px dotted white;
  }
}

.menu-description {
  //background: url("");
  height: 491px;
}

.blurred-bg {
  //background-image:url($blurred-img);
  //background-repeat:no-repeat;
  //@include background-size(cover);
  background-attachment: fixed;

  .tinted {
    background-image: (linear-gradient(0deg, rgba(255, 255, 255, .5), rgba(255, 255, 255, .5)));
  }
  .shaded {
    background-image: (linear-gradient(0deg, rgba(0, 0, 0, .2), rgba(0, 0, 0, .2)));
  }
  //background-image: -webkit-linear-gradient(90deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2)), url("https://lh3.googleusercontent.com/-m8TxQMObg6c/U474EWu7Y9I/AAAAAAAAI2k/xkRGoIEC1iU/s1600/blur.jpg")
}

.description-box {
  padding-top: 72px;
  height: calc(100vh - 172px);
  background-size: contain;
  background-repeat: no-repeat;
}

.description-content {
  text-align: right;
  padding-left: 300px;
  padding-right: 20px;
  padding-top: 25px;
  padding-bottom: 25px;
}

.slide-menu-item-title {
  width: 100%;
  border: 6px solid white;
  padding: 40px 10px;
  color: white;
  text-align: center;
  p {
    display: inline-block;
    vertical-align: middle;
  }
}

.slide-menu-item-back-drop {
  background: #000000;
  opacity: .8;
  width: 100%;
  height: 100%;
}

.menu-slide {
  min-height: 225px;
  color: white;
}

.menu-item-list {
  height: 100%;
  .swiper-button-next {
    right: 0;
    left: auto;
    height: 35px;
    width: 35px;
    background: #dddddd;
    border-radius: 50%;
    top: 90%;
    padding-left: 14px;
    padding-top: 4px;
    &:after {
      font: normal normal normal 24px FontAwesome;
      content: '\f105';
    }
  }
  .swiper-button-prev {
    left: 0;
    right: auto;
    height: 35px;
    width: 35px;
    background: #dddddd;
    border-radius: 50%;
    top: 90%;
    padding-left: 14px;
    padding-top: 4px;
    &:after {
      font: normal normal normal 24px FontAwesome;
      content: '\f104';
    }
  }
  .swiper-container {
    height: 100%;
  }
  .active {
    .slide-menu-item {
      .back-drop {
        background: transparent;
      }
    }
  }
  .slide-menu-item {
    cursor: pointer;
    color: white;
    text-align: center;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    &.active {
      .back-drop {
        background: transparent;
      }
    }
    .back-drop {
      background: rgba(0, 0, 0, .5);
    }
    .content {
      width: 80%;
      height: 80%;
      border: 4px solid white;
      .name-cat {
        position: absolute;
        width: 120px;
        height: 50px;
        z-index: 15;
        top: 50%;
        left: 50%;
        margin: -25px 0 0 -60px;
        text-transform: uppercase;
      }
    }
  }
}

.position-center {
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: auto;
  margin-top: auto;
}