@import "../../themes";
@import "../../variables";

.footer {
  &-copyright {
    border-top: 1px solid transparent;
  }

  @include themify($themes) {
    background-color: themed('defaultColor');
    &-copyright {
      border-top-color: themed('lightenColor');
      //background-color: themed('defaultColor');
    }
  }
}