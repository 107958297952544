@import "../../variables";
@import "../../themes";
//define brand
.brand {
  &-header {
    //height: 10.75rem;
    //width: 10.75rem;
    //margin-left: 5.325rem;
    width: 5rem;
    height: auto;
    margin: 0 0 0 -2.5rem;
    transition: all ease-in-out 0.325s;
    padding: 0.325rem;
    border-radius: 100%;
    background-color: $whiteColor;
    top: 0;
    display: block;
    transition: all ease-in-out 0.32s;

    &.top {

    }
    &.right {

    }
    &.bottom {
    }
    &.left {
    }
    &.middle {
    }
    &.center {

    }
  }
  &-text{
    display: block;
    position: absolute;
    width: 100%;
    padding: 1rem 1.875rem;
    text-transform: uppercase;
    font-weight: $font-weight-bold;
    text-decoration: none;
  }
  &-banner {
    width: 14.25rem;
    padding: 1.75rem;
    border-radius: 100%;
    background-color: $whiteColor;
    height: auto;
    margin-bottom: 1.5rem;
  }

  // Extra small devices (portrait phones, less than 576px)
  // No media query for `xs` since this is the default in Bootstrap

  // Small devices (landscape phones, 576px and up)
  @media (min-width: 576px) {

  }

  // Medium devices (tablets, 768px and up)
  @media (min-width: 768px) {
    &-header {
      width: 7rem;
      height: auto;
    }
  }

  // Large devices (desktops, 992px and up)
  @media (min-width: 992px) {
    &-header {
      width: 7rem;
      height: auto;
      margin-left: -3.5rem;
      padding: 0.75rem;
      top: 0.125rem;
    }
  }

  // Extra large devices (large desktops, 1200px and up)
  @media (min-width: 1200px) {
    &-header {
      width: 7rem;
      height: auto;
      margin-left: -3.5rem;
    }
  }
  @media (min-width: 1280px) {
    &-header {
      width: 7rem;
      height: auto;
      margin-left: -3.5rem;
    }
  }
  @media (min-width: 1440px) {
    &-header {
      width: 7rem;
      height: auto;
      margin-left: -3.5rem;
    }
  }
  @include themify($themes) {
    &-text{
      color: themed('defaultColor');
    }
  }
}