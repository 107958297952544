@import "../../color";

.news-detail {
  img {
    width: 100%;
    height: auto;
  }
}

.news-title {
  background-image: url(/images/bg-default.jpg);
  height: 200px;
  background-size: 100%;
  min-height: 260px;
  color: white;
  a {
    color: white;
  }
}

.box-title-news {
  width: 100%;
  background-color: $blueGray500Color;
  color: white;
  padding: 50px 20px;
  text-align: center;
  a {
    color: white;
  }
}