@import "../../variables";
.jumbotron{
  &-home-mission{
    max-width: 100%;
    margin: 0 auto;
    padding: 0 0.75rem;
    display: table;
    align-items: center;
    p{
      font-family: $font-family-specific;
      font-size: xx-large;
      font-weight: bold;
    }
  }

  @media (min-width: 576px) {
  }

  // Medium devices (tablets, 768px and up)
  @media (min-width: 768px) {
    &-home-mission{
      max-width: 30rem;
      margin-left: -15rem;
      padding: 0;

    }
  }

  // Large devices (desktops, 992px and up)
  @media (min-width: 992px) {

  }

  // Extra large devices (large desktops, 1200px and up)
  @media (min-width: 1200px) {

  }

  // Extra large devices (large desktops, 1200px and up)
  @media (min-width: 1280px) {

  }

  // Extra large devices (large desktops, 1200px and up)
  @media (min-width: 1440px) {

  }
}