@import "../../variables";
@import "../../themes";

.card {
  transition: all ease-in-out 0.32s;
  //define card for circle
  //define card store group
  &-store {
    &-group {
      .card-img-top {
        display: table;
        width: 8rem;
        height: 8rem;
        padding: 0.25rem;
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.75);
        border: 0.5rem solid transparent;
        margin: 0 auto 0.75rem;
      }
      .card-title {
        text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.75);
        text-transform: uppercase;
        margin: 0;
        font-size: $font-size-base;
      }
    }
    &-list {
      padding: 0.75rem 0;

      .card-title {
        font-size: $font-size-base;
        font-weight: $font-weight-regular;
        text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.75);
        margin: 0;
      }

      .card-img-top {
        display: block;
        width: 7.325rem;
        height: 7.325rem;
        padding: 0;
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.75);
        border: 0.325rem solid transparent;
        overflow: hidden;
        img {
          max-width: 100%;
          height: auto;
        }
      }
      .list-unstyled {
        margin: 0;
        > li {
          position: relative;
          padding: 0 0 0 1.25rem;
          font-size: $font-size-base;

          .icon {
            position: absolute;
            left: 0;
            top: .125rem;
          }
        }
      }
    }
  }
  &-menu {
    &-represent {
      border: 0.625rem solid $whiteColor;
      padding: 3rem 0;
      //padding: 2rem 0.75rem;
      .card-title {
        .title-large {
          font-size: $font-size-xxx-large;
        }
        .title-small {
          font-size: $font-size-base;
        }
      }
      .card-body {
        .link {
          font-size: $font-size-base;
          font-weight: $font-weight-bold;
          color: $whiteColor;
          text-decoration: none;
        }
      }
    }
    &-item {
      margin: 0.75rem 0;
      .card-img-top {
        opacity: 0.65;
        transition: all ease-in-out 0.32s;
        text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.75);
      }
      .card-body {
        position: absolute;
        display: flex;
        align-items: center;
        width: 100%;
        border: 8px solid rgba(255, 255, 255, 0.65);
        height: 100%;
        transition: all ease-in-out 0.32s;
        .card-title {
          width: 100%;
        }
        .link {
          font-size: $font-size-base;
          color: $whiteColor;
          text-decoration: none;
          text-shadow: 1px 1px 3px rgba(0,0,0,0.35);
        }
      }
      &:hover {
        .card-img-top {
          opacity: 1;
        }
        .card-body {
          border-color: $whiteColor;
        }
      }
    }
  }

  //card new
  &-news-item {

    &-home {
      .card-title {
        font-size: $font-size-large;
      }
      &.bg-orange {
        .card-title {
          font-size: 1.125rem;
          a {
            text-decoration: none;
            color: $whiteColor;
          }
        }
        .card-body {
          p {
            color: $whiteColor;
          }
          .link {
            text-decoration: none;
            color: $whiteColor;
          }
        }
      }
      &.bg-ft {
        .card-title {
          font-size: 1.125rem;
          a {
            text-decoration: none;
            color: $whiteColor;
          }
        }
        .card-body {
          p {
            color: $whiteColor;
          }
          .link {
            text-decoration: none;
            color: $whiteColor;
          }
        }
      }
    }
  }
  // Extra small devices (portrait phones, less than 576px)
  // No media query for `xs` since this is the default in Bootstrap

  // Small devices (landscape phones, 576px and up)
  @media (min-width: 576px) {
  }

  // Medium devices (tablets, 768px and up)
  @media (min-width: 768px) {
    &-store {
      &-group {
        .card-img-top {
          //display: table;
          width: 100%;
          height: 100%;
          //padding: 0.25rem;
          //box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.75);
          //border: 0.5rem solid transparent;
          //margin-bottom: 0.75rem;
        }
        .card-title {
          //text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.75);
          //text-transform: uppercase;
          //margin: 0;
          //font-size: $font-size-base;
        }
      }
      &-list {
        .card-title {
          font-size: $font-size-base;
          font-weight: $font-weight-regular;
          text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.75);
        }
        .card-img-top {
          width: 6.875rem;
          height: 6.875rem;
          border: 0.5rem solid transparent;
        }
      }
    }
    &-menu {
      &-represent {
        .card-title {
          .title-large {
            font-size: $font-size-xx-large;
          }
          .title-small {
            font-size: $font-size-large;
          }
        }
      }
    }
  }

  // Large devices (desktops, 992px and up)
  @media (min-width: 992px) {
    &-store {
      &-list {
        .card-title {
          font-size: $font-size-large;
          font-weight: $font-weight-regular;
          text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.75);
        }
        .card-img-top {
          width: 10rem;
          height: 10rem;
        }
      }
    }
    &-menu {
      &-represent {
        .card-title {
          .title-large {
            font-size: $font-size-xx-large;
          }
          .title-small {
            font-size: $font-size-large;
          }
        }
      }
    }
  }

  // Extra large devices (large desktops, 1200px and up)
  @media (min-width: 1200px) {
    &-store {
      &-list {
        .card-title {
          font-size: $font-size-large;
          font-weight: $font-weight-regular;
          text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.75);
        }
        .card-img-top {
          width: 10rem;
          height: 10rem;
        }
      }
    }
    &-menu {
      &-represent {
        .card-title {
          .title-large {
            font-size: $font-size-xxx-large;
          }
          .title-small {
            font-size: $font-size-large;
          }
        }
      }
    }
  }

  // Extra large devices (large desktops, 1200px and up)
  @media (min-width: 1280px) {
    &-store {
      &-list {

        .card-img-top {
          width: 11rem;
          height: 11rem;
        }
      }
    }
    &-menu {
      &-represent {
        .card-title {
          .title-large {
            font-size: $font-size-xxxx-large;
          }
          .title-small {
            font-size: $font-size-large;
          }
        }
      }
    }
  }

  // Extra large devices (large desktops, 1200px and up)
  @media (min-width: 1440px) {
    &-store {
      &-list {

        .card-img-top {
          width: 14rem;
          height: 14rem;
        }
      }
    }
  }

  @include themify($themes) {

    &-store {
      &-group {
        .card-img-top {
          background-color: themed('whiteColor');
          border-color: themed('whiteColor');
          &:hover,
          &.active {
            border-color: themed('colorSpecial');
            & + .card-title {
              color: themed('colorSpecial');
            }
          }
        }
        .card-title {
          color: themed('whiteColor');
        }
      }
      &-list {
        .card-img-top {
          background-color: themed('whiteColor');
        }
        .card-title {
          color: themed('whiteColor');
        }
        .list-unstyled {
          > li {
            color: themed('whiteColor')
          }
        }
      }
    }
    &-news-item {
      &-home {
        &.bg-ft {
          background-color: themed('defaultColor');
        }
      }
    }
  }
}