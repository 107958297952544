//@import "../../variables";
//@import "../../mixins";
@import "../../themes";

.sticky {
  &-element {
    .navbar-top {
      display: none;
      visibility: hidden;
      opacity: 0;
      transition: all ease-in-out 0.32s;
      padding: 0.5rem 0;

      // online order
      .online-order {
        //display: none;
        //visibility: hidden;
        //opacity: 0;
        //border: 1px solid white;
        //border-radius: 3px;

      }
    }
    .brand-header {
      display: none;
      visibility: hidden;
      opacity: 0;
    }
    &--sticky {
      &-top {
        .navbar-top {
          display: block;
          visibility: visible;
          opacity: 1;
          //z-index: 999;
          // online order
          //.online-order {
          //  display: table;
          //  visibility: visible;
          //  opacity: 1;
          //}
        }
        .brand-header {
          display: block;
          visibility: visible;
          opacity: 1;
        }
        .brand-text {
          background-color: rgba(255,255,255, 0.55);
        }
      }
    }
  }
  @include themify($themes) {
    &-element {
      &--sticky {
        &-top {
          .navbar-top {
            background-color: themed('whiteColor');
          }
        }
      }
    }
  }
  // Extra small devices (portrait phones, less than 576px)
  // No media query for `xs` since this is the default in Bootstrap

  // Small devices (landscape phones, 576px and up)
  @media (min-width: 576px) {

  }

  // Medium devices (tablets, 768px and up)
  @media (min-width: 768px) {

  }

  // Large devices (desktops, 992px and up)
  @media (min-width: 992px) {
    &--sticky {
      &-top {

      }
    }
  }

  // Extra large devices (large desktops, 1200px and up)
  @media (min-width: 1200px) {

  }
  // Extra large devices (large desktops, 1200px and up)
  @media (min-width: 1280px) {

  }
  // Extra large devices (large desktops, 1200px and up)
  @media (min-width: 1440px) {
    &--sticky {
      &-top {

      }
    }
  }
}