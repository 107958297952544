@import "variables";

.app{
  &-site{
    font-family: $font-family-text;
    .main{
      min-height: 70vh;
    }

    .z-index-2 {
      z-index: 2 !important;
    }

    .py-6 {
      padding-bottom: 4.75rem;
      padding-top: 4.75rem;
    }
  }
}