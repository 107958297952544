@import "ember-bootstrap/bootstrap";
@import 'ember-power-select/themes/bootstrap';
@import "ember-power-select";
@import "ember-side-menu";
@import "functions";
@import "variables";
@import "mixins";
@import "root";
@import "color.scss";
@import "mobile.scss";
@import "spinner.scss";
@import "blog.scss";
//site style
@import "site/index";

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

body {
  max-width: 1920px;
  //min-width: 1349px;
  margin: auto;
}

.ember-power-select-dropdown {
  z-index: 1051;
}

.position-absolute-0 {
  top: 0;
  left: 0;
}

.bg-no-repeat {
  background-repeat: no-repeat !important;
}

.bg-100 {
  background-size: 100% 100% !important;
}

.font-sm {
  font-size: 12px !important;
}

.font-normal {
  font-size: 14px !important;
}

.main-menu {
  text-align: center;
  text-transform: uppercase;
  ul {
    list-style: none;
    padding: 0;
    li {
      display: inline-block;
      padding: 25px 18px;
      .active {
        font-size: 18px;
        font-weight: 700;
      }
      a {
        display: inline-block;
        color: #53784b;
        font-weight: 500;
        &:hover {
          text-decoration: none;
        }
      }
    }
  }
}

.box-slogan {
  width: 320px;
  padding: 30px 10px 10px 10px;
  margin-top: 250px;
  margin-left: 35px;
  .title {
    top: -15px;
    left: -15px;
    background: #fff;
    padding: 0 15px 15px 0;
    font-weight: 500;
    p {
      background: #1b550d;
      color: white;
      padding: 5px 10px;
      margin: 0;
    }
  }
}

.icon-circular-frame {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  text-align: center;
  display: inline-block;
  i {
    color: white;
    vertical-align: bottom;
  }
}

.footer {
  //border-top-color: $main-green;
  //border-top-color: transparent;
}

//home
.home-menu {
  background-color: #131313;
  margin: 30px 0;
  padding-top: 30px;
  box-shadow: -2px 2px 1px #131313;
  ul {
    list-style-type: none;
    padding: 0;
    text-align: center;
    li {
      display: inline-block;
      max-width: 17%;
      color: white;
      font-size: 13px;
      img {
        width: 40px;
        margin-bottom: 10px;
      }
    }
  }
}

.home-news {
  color: white;
  .box-news {
    a {
      color: white;
    }
  }
  .backdrop {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #1b550d;
    opacity: 0.5
  }
}

.no-margin-top {
  margin-top: 0 !important;
}

.table-borderless td,
.table-borderless th {
  border: 0;
}

//contact

.contact-container {
  padding-left: 20px;
}

.contact-form-container {
  padding-left: 20px;
  padding-right: 20px;
  text-transform: uppercase;
  color: white;
}

.contact-form-container textarea {
  height: 150px;
}

.contact-form-container .input-error .error {
  color: white;
  text-transform: none;
}

.contact-form-container .ember-power-select-selected-item {
  color: #333333;
}

//end menu style

//start intro style
.card-intro {
  background-color: #f6fffc;
  border: none;

  .nav-tabs {
    .nav-item {

      // margin-bottom: -2px;
      //border-bottom: 1px solid #7a8380;

      .nav-link {
        color: #4e584d;
        background-color: #cccccc;
        border-radius: 0;
        position: relative;

        &.active,
        &:hover {
          background-color: transparent;
          border-color: #7a8380;
          border-bottom-color: #f6fffc;

          &::after {
            width: 100%;
            height: 1px;
            position: absolute;
            bottom: -2px;
            background-color: #f6fffc;
            content: '';
            left: 0;
            z-index: 99;
          }

        }
      }
    }
  }
  .tab-content {
    padding: 1.5rem 2rem;
    border: 1px solid #7a8380;
  }
}

//end intro style

//start gallery style
.gallery {

  position: relative;
  background-image: url(/images/bg-gallery.jpeg);
  background-size: cover;
  background-attachment: fixed;

  &-bg-animation {

  }
  &-bg-mask {
    background: rgba(255, 255, 255, 0.35);
    -webkit-filter: blur(5px);
    -moz-filter: blur(5px);
    -o-filter: blur(5px);
    -ms-filter: blur(5px);
    filter: blur(5px);
  }
}

.gallery img:hover {
  -webkit-filter: brightness(1.1);
  filter: brightness(1.1);
}

//end gallery style

//login
.e-box-signin {
  width: 500px;
  margin: auto;
  background: rgba(27, 85, 13, 0.5);
  padding: 20px;
  color: white;
  margin-bottom: 30px;
}

//footer
.footer-content p {
  margin-bottom: 10px;
}

.footer-content p.site-name {
  font-size: 200%;
  text-transform: uppercase;
}

//end footer
.store-box {
  border-radius: 3px;
  border: 1px solid;
  padding: 5px;
  margin-bottom: 10px;
  display: block;
  color: #333;
}

.store-box-control {
  max-height: 650px;
  overflow-y: scroll;
}