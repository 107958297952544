@import "../../variables";
@import "../../functions";
.header{

  &-st1{
    position: relative;
    z-index: 1;
    .btn-toggle{
      font-size: $font-size-large;
      padding: 0.875rem;
      line-height: 1.5rem;
      border: 1px solid rgba(255,255,255, 0.75);
      background-color: rgba(255,255,255,0.65);
    }
  }
}