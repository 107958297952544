.list{
  &-store{
    height: 22rem;
    padding: 0.75rem 0 3.25rem;
    .list-group{
      background-color: transparent;
      &-item{
        background-color: transparent;
      }
    }
  }

  // Extra small devices (portrait phones, less than 576px)
  // No media query for `xs` since this is the default in Bootstrap

  // Small devices (landscape phones, 576px and up)
  @media (min-width: 576px) {  }

  // Medium devices (tablets, 768px and up)
  @media (min-width: 768px) {
    &-store{
      height: 36rem;
      padding: 1.75rem 0;
      .list-group{
        background-color: transparent;
        &-item{
          background-color: transparent;
        }
      }
    }
  }

  // Large devices (desktops, 992px and up)
  @media (min-width: 992px) {
    &-store{
      height: calc(100vh - 36px);
      padding: 3.75rem 0;
    }
  }

  // Extra large devices (large desktops, 1200px and up)
  @media (min-width: 1200px) {
    &-store{
      //height:36rem;
    }
  }

  // Extra large devices (large desktops, 1200px and up)
  @media (min-width: 1280px) {
    &-store{
      //height: 40rem;
    }
  }

  // Extra large devices (large desktops, 1200px and up)
  @media (min-width: 1440px) {
    &-store{
      //height: 36rem;
    }
  }
}