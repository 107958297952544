@import "../../themes";
@import "../../variables";

.navbar {
  //navbar for top
  &-top{
    
  }
  //navbar for left
  &-left {
    //& + .btn-toggle {
    //  padding: 0.875rem 0.5rem;
    //}
    &.cbp-spmenu-open {
      & + .btn-toggle {
        left: 15rem;
      }
    }
  }
  @include themify($themes) {
    &-left {
      background-color: themed('bgColor');
    }
  }
}

//.navbar-left.cbp-spmenu-open + .btn-toggle {
//  left: 280px;
//}

