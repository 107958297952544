@import "../../color";

.recruitment-title {
  padding-left: 250px;
  padding-top: 150px;
  background: url("/images/bg-application.png") no-repeat;
  background-size: cover;
  color: white;
  min-height: 600px;
}

.recruitment {
  .cp-is-open {
    span {
      &:before {
        content: "\f068";
      }
    }
  }
  //padding: 20px;
  padding-top: 60px;
  .list-store {
    height: auto;
    .cp-is-open {
      span {
        &:before {
          content: "\f068";
        }
      }
    }
    .header-toggle {
      padding: 5px;
      margin: 15px 20px 0 20px;
      border: 1px solid #bbbbbb;
      box-shadow: 0px 2px 3px 0px #bbbbbb;
      font-size: 20px;
      font-weight: 500;
      span {
        clear: both;
        float: none;
      }
    }
    .nav-content {
      margin: 0 20px;
      background-color: #eeeeee;
      margin-top: 3px;
      a {
        color: $blueGray500Color;
        &:hover {
          color: $orange900Color;
        }
      }
    }
  }
  .recruitment-mission {
    padding: 0 200px;
    .header-toggle {
      padding: 5px;
      border: 1px solid #bbbbbb;
      box-shadow: 0px 2px 3px 0px #bbbbbb
    }
  }
  a {
    &:hover {
      text-decoration: none;
    }
  }
  .header-toggle {
    padding: 10px;
    box-shadow: 0px 0px 3px 0px #bbbbbb;
    background-color: white;
    color: $blueGray500Color;
    font-size: 22px;
    font-weight: 600;
  }
}

.modal-application {
  .modal-header {
    background-color: $blueGray800Color;
    color: white;
  }
}

.recruitment-mission {
  padding: 0 200px;
}

.recruitment-note {
  //padding: 0 400px;
}

.font-dancing-script {
  font-family: "Dancing Script", cursive;
  p {
    font-family: "Dancing Script", cursive;
  }
}